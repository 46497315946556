<template>
  <div
    class="review-item__inout review-item__inner"
    :class="{ 'review-item__inner-flex': reviewPhotoType==='Smallpic' }"
  >
    <div>
      <!-- Content -->
      <CommentContent 
        :position="position" 
        :showReviewPhoto="reviewPhotoType"
        :reviewPhotoType="reviewPhotoType"
      />
      <!-- Translate -->
      <CommentTranslate
        v-if="COMMENT_INSTANCE.showTranslate"
      />
    </div>
    <!-- Images -->
    <CommentImages
      v-if="!COMMENT_INSTANCE.isOutStoreReview"
      :reviewPhotoType="reviewPhotoType"
    />
  </div>
</template>

<script>
import CommentTranslate from './components/CommentTranslate'
import CommentContent from './components/CommentContent'
import CommentImages from './components/CommentImages'

export default {
  name: 'CommentInner',
  components: { CommentContent, CommentTranslate, CommentImages },
  inject: ['COMMENT_INSTANCE'],
  props: {
    position: {
      type: String,
      default: 'outer'
    },
    reviewPhotoType: {
      type: String,
      default: 'None'
    },
  },

}
</script>
<style lang="less">
.review-item {
  &__inner-flex {
    display: flex;
    justify-content: space-between;
    gap: .2133rem;
  }
}
</style>
